import React, { useState, useMemo } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  TablePagination,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import * as XLSX from 'xlsx';

function ClaimsSummaryDataTable({ data }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatColumnHeader = (column) => {
    if (column.match(/^\d{4}[-_]\d{2}$/)) {
      const [year, month] = column.split(/[-_]/);
      const date = new Date(parseInt(year), parseInt(month) - 1);
      return date.toLocaleString('default', { month: 'short', year: '2-digit' });
    }
    return replaceUnderscores(column);
  };

  const formatDateCell = (dateString) => {
    if (dateString && dateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
      const [year, month, day] = dateString.split('-');
      const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
      return date.toLocaleString('default', { day: '2-digit', month: 'short', year: '2-digit' });
    }
    return dateString;
  };

  const formatNumber = (value) => {
    if (typeof value === 'number') {
      return value.toLocaleString('en-US', { maximumFractionDigits: 2 });
    }
    return value;
  };

  const replaceUnderscores = (text) => {
    return typeof text === 'string' ? text.replace(/_/g, ' ') : text;
  };

  const getLastThreeDigits = (str) => {
    const digits = str.replace(/\D/g, '');
    return digits.slice(-3).padStart(3, '0');
  };

  const processedData = useMemo(() => {
    if (!Array.isArray(data) || data.length === 0) return [];

    const columns = Object.keys(data[0]);
    const processed = data.map(row => {
      const newRow = { ...row };
      columns.forEach(column => {
        if (newRow[column] === '' || newRow[column] === 'nan' || newRow[column] === null || newRow[column] === undefined) {
          newRow[column] = '-';
        } else if (typeof newRow[column] === 'number' && isNaN(newRow[column])) {
          newRow[column] = '-';
        }
      });
      return newRow;
    });

    return processed.sort((a, b) => {
      const aDigits = getLastThreeDigits(a.Policy_Number);
      const bDigits = getLastThreeDigits(b.Policy_Number);
      return aDigits.localeCompare(bDigits);
    });
  }, [data]);

  const handleExportExcel = () => {
    const ws = XLSX.utils.json_to_sheet(processedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    XLSX.writeFile(wb, "claims_data.xlsx");
  };

  const renderCellContent = (row, column) => {
    if (column === 'Inception_Date' || column === 'Expiry_Date') {
      return formatDateCell(row[column]);
    } else if (column.includes('20')) {
      return formatNumber(row[column]);
    }
    return row[column];
  };

  if (!Array.isArray(data)) {
    return (
      <Typography variant="body1" align="center" sx={{ p: 2, color: 'text.secondary' }}>
        Invalid data format. Please try again.
      </Typography>
    );
  }

  if (data === null || data.length === 0) {
    return (
      <Typography variant="body1" align="center" sx={{ p: 2, color: 'text.secondary' }}>
        No data for your filter parameters.
      </Typography>
    );
  }

  if (!processedData || processedData.length === 0) {
    return (
      <Typography variant="body1" align="center" sx={{ p: 2, color: 'text.secondary' }}>
        No data selected.
      </Typography>
    );
  }
  const compactStyles = {
    tableCell: {
      padding: '6px 8px',
      fontSize: '0.8rem',
    },
    stickyCell: {
      minWidth: '150px',
      maxWidth: '150px',
    },
    dataCell: {
      minWidth: '100px',
      maxWidth: '100px',
    },
  };

  const columns = Object.keys(processedData[0]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Paper sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <TableContainer sx={{ flexGrow: 1, height: 0, overflow: 'auto' }}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={column}
                    style={{
                      ...compactStyles.tableCell,
                      position: 'sticky',
                      top: 0,
                      left: index === 0 ? 0 : index === 1 ? '150px' : 'auto',
                      zIndex: index < 2 ? 3 : 2,
                      backgroundColor: '#f0f0f0',
                      ...(index < 2 ? compactStyles.stickyCell : compactStyles.dataCell),
                      textAlign: column.startsWith('20') ? 'right' : 'left',
                    }}
                  >
                    {formatColumnHeader(column)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {processedData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowIndex) => (
                  <TableRow hover key={rowIndex}>
                    {columns.map((column, columnIndex) => (
                      <TableCell 
                        key={column}
                        style={{ 
                          ...compactStyles.tableCell,
                          backgroundColor: '#ffffff',
                          position: columnIndex < 2 ? 'sticky' : 'static',
                          left: columnIndex === 0 ? 0 : columnIndex === 1 ? '150px' : 'auto',
                          zIndex: columnIndex < 2 ? 1 : 0,
                          borderRight: '1px solid #e0e0e0',
                          ...(columnIndex < 2 ? compactStyles.stickyCell : compactStyles.dataCell),
                          whiteSpace: 'normal',
                          wordWrap: 'break-word',
                          textAlign: column.startsWith('20') ? 'right' : 'left',
                        }}
                      >
                        {renderCellContent(row, column)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' , marginLeft: '10px'}}>
            <Tooltip title="Download as Excel">
              <IconButton onClick={handleExportExcel}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={processedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    </div>
  );
}

export default ClaimsSummaryDataTable;