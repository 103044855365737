import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const FormulaContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  minHeight: '2.5rem',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(0.5),
}));

const OperatorSpan = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(0.25, 0.5),
  borderRadius: theme.shape.borderRadius,
  fontWeight: 'bold',
}));

const VariableSpan = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.secondary.contrastText,
  padding: theme.spacing(0.25, 0.5),
  borderRadius: theme.shape.borderRadius,
}));

const VariableElement = ({ value }) => (
  <VariableSpan>{value}</VariableSpan>
);

const OperatorElement = ({ value }) => (
  <OperatorSpan>{value}</OperatorSpan>
);

const FormulaDisplay = ({ formulaElements }) => {
  return (
    <FormulaContainer>
      {formulaElements.map((element, index) => 
        element.type === 'operator' 
          ? <OperatorElement key={index} value={element.value} />
          : <VariableElement key={index} value={element.value} />
      )}
    </FormulaContainer>
  );
};

export default FormulaDisplay;