import React, { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import AddBordereaux from './AddBordereaux';

function AddBordereauxTabs({ companyName, wholesalerContractMap }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="bordereaux tabs">
          <Tab label="Premiums" />
          <Tab label="Claims" />
        </Tabs>
      </Box>
      <Box sx={{ p: 3 }}>
        {value === 0 && (
          <AddBordereaux
            companyName={companyName}
            wholesalerContractMap={wholesalerContractMap}
            borderauType="premium"
          />
        )}
        {value === 1 && (
          <AddBordereaux
            companyName={companyName}
            wholesalerContractMap={wholesalerContractMap}
            borderauType="claim"
          />
        )}
      </Box>
    </Box>
  );
}

export default AddBordereauxTabs;