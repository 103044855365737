import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Box, Menu, MenuItem, IconButton, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import { useAuth } from '../contexts/AuthContext';
import { styled } from '@mui/material/styles';
import { Link as MuiLink } from '@mui/material';
import { motion } from 'framer-motion';

const GradientAppBar = styled(AppBar)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.dark} 90%)`,
  height: '80px',
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
}));

const Logo = styled('img')({
  height: '5.5vh',
  marginRight: '10px',
});

const HeaderButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  fontSize: '1rem',
  fontWeight: 500,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const AnimatedHeaderButton = motion(HeaderButton);

function Header() {
  const { logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <GradientAppBar position="static">
      <Toolbar sx={{ 
        justifyContent: 'space-between', 
        height: '100%',
        alignItems: 'center',
        padding: { xs: '0 16px', sm: '0 24px' },
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <MuiLink href="https://localhost:3001">
            <Logo src={`${window.location.origin}/logo-512.png`} alt="BIM Logo" />
          </MuiLink>
        </Box>
        <Box sx={{ 
          display: { xs: 'none', md: 'flex' },
          height: '100%',
          alignItems: 'center'
        }}>
          <AnimatedHeaderButton
            color="inherit"
            component={Link}
            to="/"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Dashboard
          </AnimatedHeaderButton>
          <AnimatedHeaderButton
            color="inherit"
            component={Link}
            to="/manage-data"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Manage Data
          </AnimatedHeaderButton>
          <AnimatedHeaderButton
            color="inherit"
            component={Link}
            to="/settings"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            startIcon={<SettingsIcon />}
          >
            Settings
          </AnimatedHeaderButton>
          <AnimatedHeaderButton
            color="inherit"
            onClick={logout}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            startIcon={<PersonIcon />}
          >
            Logout
          </AnimatedHeaderButton>
        </Box>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ 
            display: { xs: 'block', md: 'none' },
            alignSelf: 'center'
          }}
          onClick={handleMenu}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose} component={Link} to="/">Dashboard</MenuItem>
          <MenuItem onClick={handleClose} component={Link} to="/manage-data">Manage Data</MenuItem>
          <MenuItem onClick={handleClose} component={Link} to="/settings">
            <SettingsIcon sx={{ marginRight: 1 }} /> Settings
          </MenuItem>
          <MenuItem onClick={() => { handleClose(); logout(); }}>
            <PersonIcon sx={{ marginRight: 1 }} /> Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </GradientAppBar>
  );
}

export default Header;