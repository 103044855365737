// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Main project config (for authentication)
const mainFirebaseConfig = {
  apiKey: process.env.REACT_APP_MAIN_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_MAIN_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_MAIN_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_MAIN_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MAIN_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_MAIN_FIREBASE_APP_ID
};

// Platform project config (for Firestore and Storage)
const platformFirebaseConfig = {
  apiKey: process.env.REACT_APP_PLATFORM_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_PLATFORM_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PLATFORM_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PLATFORM_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PLATFORM_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_PLATFORM_FIREBASE_APP_ID
};

const mainApp = initializeApp(mainFirebaseConfig, 'main');
const platformApp = initializeApp(platformFirebaseConfig, 'platform');

const mainAuth = getAuth(mainApp);           // For authentication with the main project
const platformAuth = getAuth(platformApp);   // For authentication with the platform project
const db = getFirestore(platformApp);        // For Firestore operations on the platform project
const storage = getStorage(platformApp);     // For Storage operations on the platform project

export { mainApp, platformApp, mainAuth, platformAuth, db, storage };