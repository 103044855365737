import React, { useState } from 'react';
import { Typography, Box, IconButton, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ReactMarkdown from 'react-markdown';
import CustomTooltip from './CustomTooltip';

function AISummary({ paymentsSummary }) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const formatSummary = (summary) => {
    if (summary === null || summary === undefined) {
      return "No missed payments.";
    }

    if (Object.keys(summary).length === 0) {
      return "No missed payments.";
    }

    let formattedSummary = "**Missed Payments:**\n\n";
    for (const [company, months] of Object.entries(summary)) {
      formattedSummary += `### ${company}:\n${months.join(', ')}\n\n`;
    }
    return formattedSummary.trim();
  };


  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" gutterBottom>
          Summary
          <CustomTooltip title="Missed Payments Summary" content="Here you can see a summary of the payments that have been missed for each insured in the given date range." />
        </Typography>
        <IconButton onClick={toggleExpand} size="small">
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      <Collapse in={expanded}>
        <Box
          sx={{
            mt: 2,
            border: '1px solid black',
            borderRadius: 1,
            p: 2,
            minHeight: '35vh',
            maxHeight: '40vh',
            overflowY: 'auto'
          }}
        >
          <ReactMarkdown
            components={{
              p: ({ node, ...props }) => <Typography variant="body2" {...props} />,
              h1: ({ node, ...props }) => <Typography variant="h6" {...props} />,
              h2: ({ node, ...props }) => <Typography variant="h6" {...props} />,
              h3: ({ node, ...props }) => <Typography variant="subtitle1" fontWeight="bold" {...props} />,
              li: ({ node, ...props }) => <Typography component="li" variant="body2" {...props} />,
            }}
          >
            {formatSummary(paymentsSummary)}
          </ReactMarkdown>
        </Box>
      </Collapse>
    </Box>
  );
}

export default AISummary;