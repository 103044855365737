import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Typography, CircularProgress, TextField, Select, MenuItem, FormControl, InputLabel, Grid, Checkbox, InputAdornment } from '@mui/material';
import { db } from '../services/firebase';
import { doc, getDoc, setDoc, collection, getDocs } from 'firebase/firestore';
import SchemaBuilder from './SchemaBuilder';
import MappingDefinition from './MappingDefinition';
import CustomTooltip from './CustomTooltip';
const classesOfBusiness = [
  'Commercial Automobile Physical Damage',
  'Motor Truck Cargo',
  'Contingent Motor Truck Cargo',
  'Excess Commercial Automobile Physical Damage',
  'Excess Motor Truck Cargo',
  'Non-Trucking Liability',
  'Non-Trucking Liability iro Unladen',
  'Non-Trucking Liability iro Bobtail',
  'Deductible Buy Down',
  'Umbrella / Excess Liability',
  'Dealers Open Lot',
  'Garage Keepers Legal Liability',
  'Commercial General Liability',
  'Terrorism',
  'Occupational Accident',
  'General Liability',
  'Contingent Liability',
  'Contingent Automobile Liability',
  'Residential',
  'Property',
  'Commercial Property',
  'Marine Cargo',
  'Inland Marine'
];

const ContractEditor = ({ companyName, wholesalerId, contractId, onSave, onCancel }) => {
  const [contract, setContract] = useState(null);
  const [loading, setLoading] = useState(true);
  const [syndicates, setSyndicates] = useState([]);
  const [error, setError] = useState('');
  const [commissions, setCommissions] = useState({});

  useEffect(() => {
    const fetchSyndicates = async () => {
      try {
        const syndicatesRef = collection(db, `users/${companyName}/syndicates`);
        const syndicatesSnapshot = await getDocs(syndicatesRef);
        const syndicatesList = syndicatesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setSyndicates(syndicatesList);
      } catch (err) {
        console.error('Failed to fetch syndicates:', err);
      }
    };

    fetchSyndicates();
  }, [companyName]);

  useEffect(() => {
    const fetchContract = async () => {
      try {
        if (contractId && contractId !== '') {
          const contractRef = doc(db, `users/${companyName}/wholesalers/${wholesalerId}/contracts`, contractId);
          const contractSnap = await getDoc(contractRef);
          if (contractSnap.exists()) {
            const fetchedContract = contractSnap.data();
            const processedContract = { ...fetchedContract };
            ['premiums_mappings', 'claims_mappings'].forEach(mappingType => {
              const mappings = fetchedContract[mappingType];
              const processedMappings = {};
              if (mappings) {
                Object.entries(mappings).forEach(([dbColumn, mappingValue]) => {
                  if (typeof mappingValue === 'string') {
                    if (mappingValue.includes('`')) {
                      const regex = /(`[^`]+`)|([^`]+)/g;
                      const tokens = mappingValue.match(regex);
                      const formulaElements = tokens.map(token => {
                        if (token.startsWith('`') && token.endsWith('`')) {
                          return { type: 'operator', value: token.slice(1, -1) };
                        } else {
                          return { type: 'variable', value: token.trim() };
                        }
                      });
                      processedMappings[dbColumn] = {
                        type: 'FORMULA',
                        formulaElements: formulaElements,
                      };
                    } else {
                      processedMappings[dbColumn] = {
                        type: 'DIRECT',
                        value: mappingValue,
                      };
                    }
                  } else {
                    processedMappings[dbColumn] = mappingValue;
                  }
                });
              }
              processedContract[mappingType] = processedMappings;
            });
            setContract(processedContract);
            setCommissions(fetchedContract.commissions || {});
          } else {
            throw new Error('Contract not found');
          }
        } else {
          setContract({
            id: '',
            premiums_schema: [],
            claims_schema: [],
            premiums_mappings: {},
            claims_mappings: {},
            syndicate_id: '',
            date_format: '',
          });
          setCommissions({});
        }
      } catch (err) {
        setError('Failed to fetch contract');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchContract();
  }, [companyName, wholesalerId, contractId]);

  const handleSchemaChange = useCallback((type, newSchema) => {
    setContract(prevContract => ({
      ...prevContract,
      [`${type}_schema`]: newSchema
    }));
  }, []);

  const handleMappingChange = useCallback((type, newMappings) => {
    setContract(prevContract => ({
      ...prevContract,
      [`${type}_mappings`]: newMappings
    }));
  }, []);

  const handleInputChange = (field, value) => {
    setContract(prevContract => ({
      ...prevContract,
      [field]: value,
    }));
  };

  const postprocessContract = (contract) => {
    const processedContract = { ...contract };
    ['premiums_mappings', 'claims_mappings'].forEach(mappingType => {
      const processedMappings = {};
      Object.entries(contract[mappingType]).forEach(([dbColumn, mappingValue]) => {
        if (mappingValue.type === 'FORMULA') {
          processedMappings[dbColumn] = mappingValue.formulaElements.map(el => el.type === 'operator' ? `\`${el.value}\`` : el.value).join(' ');
        } else {
          processedMappings[dbColumn] = mappingValue.value;
        }
      });
      processedContract[mappingType] = processedMappings;
    });
    processedContract.commissions = commissions;
    return processedContract;
  };

  const handleSave = async () => {
    try {
      const processedContract = postprocessContract(contract);
      const contractRef = doc(db, `users/${companyName}/wholesalers/${wholesalerId}/contracts`, contract.id || Date.now().toString());
      await setDoc(contractRef, processedContract);
      onSave(processedContract);
    } catch (err) {
      setError('Failed to save contract');
      console.error(err);
    }
  };

  const handleCommissionChange = (classOfBusiness, checked, value) => {
    setCommissions(prev => {
      const updated = { ...prev };
      if (checked) {
        updated[classOfBusiness] = value || '';
      } else {
        delete updated[classOfBusiness];
      }
      return updated;
    });
  };

  const handleCommissionValueChange = (classOfBusiness, value) => {
    setCommissions(prev => ({
      ...prev,
      [classOfBusiness]: value,
    }));
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        {contractId && contractId !== '' ? 'Edit Contract' : 'New Contract'}
      </Typography>
      <Box mb={2}>
        <TextField
          label="Contract ID"
          value={contract.id}
          onChange={(e) => handleInputChange('id', e.target.value)}
          fullWidth
          required
        />
      </Box>
      <Box mb={2}>
        <FormControl fullWidth required>
          <InputLabel>Date Format</InputLabel>
          <Select
            label="Date Format"
            value={contract.date_format}
            onChange={(e) => handleInputChange('date_format', e.target.value)}
          >
            <MenuItem value="EN">EN</MenuItem>
            <MenuItem value="US">US</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box mb={4}>
        <FormControl fullWidth required>
          <InputLabel>Syndicate</InputLabel>
          <Select
            label="Syndicate"
            value={contract.syndicate_id}
            onChange={(e) => handleInputChange('syndicate_id', e.target.value)}
          >
            {syndicates.map((syndicate) => (
              <MenuItem key={syndicate.id} value={syndicate.id}>
                {syndicate.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>Commission Percentages
          <CustomTooltip title="Commission Percentages" content="Here you can define which classes of business will be associated with this contract and the commission percentages for each class of business." />
        </Typography>
        <Grid container spacing={2}>
          {classesOfBusiness.map((classOfBusiness, index) => (
            <Grid container item xs={12} sm={6} alignItems="center" key={index}>
              <Grid item>
                <Checkbox
                  checked={commissions.hasOwnProperty(classOfBusiness)}
                  onChange={(e) => handleCommissionChange(classOfBusiness, e.target.checked)}
                />
              </Grid>
              <Grid item xs>
                <Typography>{classOfBusiness}</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  value={commissions[classOfBusiness] || ''}
                  onChange={(e) => handleCommissionValueChange(classOfBusiness, e.target.value)}
                  disabled={!commissions.hasOwnProperty(classOfBusiness)}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>Premiums Bordereau Format
          <CustomTooltip title="Define the Format of the Bordereau you will receive for Premiums" content="" />
        </Typography>
        <SchemaBuilder
          initialSchema={contract.premiums_schema}
          onSchemaChange={(newSchema) => handleSchemaChange('premiums', newSchema)}
        />
      </Box>
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>Claims Bordereau Format
          <CustomTooltip title="Define the Format of the Bordereau you will receive for Claims" content="" />
        </Typography>
        <SchemaBuilder
          initialSchema={contract.claims_schema}
          onSchemaChange={(newSchema) => handleSchemaChange('claims', newSchema)}
        />
      </Box>
      <Box mb={4}>
        <MappingDefinition
          schema={contract.premiums_schema}
          initialMappings={contract.premiums_mappings}
          onMappingChange={(newMappings) => handleMappingChange('premiums', newMappings)}
          companyName={companyName}
          type="premiums"
        />
      </Box>
      <Box mb={4}>
        <MappingDefinition
          schema={contract.claims_schema}
          initialMappings={contract.claims_mappings}
          onMappingChange={(newMappings) => handleMappingChange('claims', newMappings)}
          companyName={companyName}
          type="claims"
        />
      </Box>
      <Box>
        <Button onClick={handleSave} variant="contained" color="primary" sx={{ mr: 2 }}>
          Save
        </Button>
        <Button onClick={onCancel} variant="outlined">
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default ContractEditor;
