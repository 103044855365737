import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, FormGroup, FormControlLabel, Checkbox, Button, Grid, Paper, Switch } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useDashboard } from '../contexts/DashboardContext';
import { db } from '../services/firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';

const columnOptions = {
  premiums: ['Policy Number', 'Insured Name', 'Class of Business', 'Period Starting', 'Policyholder Type', 'Inception Date', 'Expiry Date', 'State', 'Estimated Annual Premium', 'Payment Plan', 'Deductible', 'Deductible Basis', 'Transaction Type', 'Transaction Date', 'Gross Premium', 'Coverholder Commission (%)', 'Coverholder Commission (Amount)', 'Net Premium', 'Surplus Line Broker', 'Address of Broker', 'State of Filing', 'Broker License Number'],
  claims: ['Claim Number', 'Policy Number', 'Insured Name', 'Inception Date', 'Expiry Date', 'Surplus Line State', 'Loss Location', 'Date of Loss', 'Type of Loss', 'Loss Description', 'Denial', 'Settled > Indemnity', 'Settled > Subro and Salvage', 'Settled > Fees', 'Total > Indemnity', 'Total > Subro and Salvage', 'Total > Fees', 'Total > Combined']
};
const mandatoryPremiumColumns = ['Policy Number', 'Insured Name', 'Period Starting', 'Policyholder Type', 'Inception Date', 'Expiry Date', 'Payment Plan', 'Gross Premium', 'Net Premium', 'Surplus Line Broker', 'Broker License Number'];
const mandatoryClaimsColumns = ['Claim Number', 'Policy Number', 'Insured Name', 'Inception Date', 'Expiry Date', 'Total > Combined'];

function Settings() {
  const { currentUser, companyName } = useAuth();
  const { showTooltips, setShowTooltips } = useDashboard();
  const [selectedPremiumColumns, setSelectedPremiumColumns] = useState([]);
  const [selectedClaimsColumns, setSelectedClaimsColumns] = useState([]);

  useEffect(() => {
    const fetchSettings = async () => {
      if (companyName) {
        const userDoc = await getDoc(doc(db, 'users', companyName));

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setSelectedPremiumColumns(userData.premiums_target_columns || []);
          setSelectedClaimsColumns(userData.claims_target_columns || []);
        }
      }
    };

    fetchSettings();
  }, [companyName]);

  const handlePremiumColumnChange = (column) => {
    if (!mandatoryPremiumColumns.includes(column)) {
      setSelectedPremiumColumns(prev =>
        prev.includes(column) ? prev.filter(c => c !== column) : [...prev, column]
      );
    }
  };

  const handleClaimsColumnChange = (column) => {
    if (!mandatoryClaimsColumns.includes(column)) {
      setSelectedClaimsColumns(prev =>
        prev.includes(column) ? prev.filter(c => c !== column) : [...prev, column]
      );
    }
  };

  const saveSettings = async () => {
    if (companyName) {
      const userDocRef = doc(db, 'users', companyName);
      const updatedPremiumColumns = [...new Set([...mandatoryPremiumColumns, ...selectedPremiumColumns])];
      const updatedClaimsColumns = [...new Set([...mandatoryClaimsColumns, ...selectedClaimsColumns])];
      await setDoc(userDocRef, {
        premiums_target_columns: updatedPremiumColumns,
        claims_target_columns: updatedClaimsColumns
      }, { merge: true });
      alert('Settings saved successfully!');
    }
  };

  const sortedPremiumColumns = [
    ...mandatoryPremiumColumns,
    ...columnOptions.premiums
      .filter(column => !mandatoryPremiumColumns.includes(column))
      .sort((a, b) => a.localeCompare(b))
  ];

  const sortedClaimsColumns = [
    ...mandatoryClaimsColumns,
    ...columnOptions.claims
      .filter(column => !mandatoryClaimsColumns.includes(column))
      .sort((a, b) => a.localeCompare(b))
  ];

  return (
    <Container component="main" maxWidth="md" sx={{ mt: 8 }}>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Settings
      </Typography>
      <Box sx={{ mb: 4 }}>
        <FormControlLabel
          control={
            <Switch
              checked={showTooltips}
              onChange={(e) => setShowTooltips(e.target.checked)}
              color="primary"
            />
          }
          label="Enable Tooltips"
          labelPlacement="start"
        />
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Premium Bordereaux Columns
            </Typography>
            <FormGroup>
              {sortedPremiumColumns.map((column) => (
                <FormControlLabel
                  key={column}
                  control={
                    <Checkbox
                      checked={selectedPremiumColumns.includes(column) || mandatoryPremiumColumns.includes(column)}
                      onChange={() => handlePremiumColumnChange(column)}
                      disabled={mandatoryPremiumColumns.includes(column)}
                    />
                  }
                  label={column}
                />
              ))}
            </FormGroup>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Claims Bordereaux Columns
            </Typography>
            <FormGroup>
              {sortedClaimsColumns.map((column) => (
                <FormControlLabel
                  key={column}
                  control={
                    <Checkbox
                      checked={selectedClaimsColumns.includes(column) || mandatoryClaimsColumns.includes(column)}
                      onChange={() => handleClaimsColumnChange(column)}
                      disabled={mandatoryClaimsColumns.includes(column)}
                    />
                  }
                  label={column}
                />
              ))}
            </FormGroup>
          </Paper>
        </Grid>
      </Grid>
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" color="primary" onClick={saveSettings}>
          Save Settings
        </Button>
      </Box>
    </Container>
  );
}

export default Settings;