import React, { useState, useMemo } from 'react';
import { Typography, Box, Select, MenuItem, InputLabel, CircularProgress, IconButton, Collapse, TextField, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { FetchDataButton } from './Buttons';

function FilterOptions({
  wholesalerContractMap,
  selectedWholesaler,
  setSelectedWholesaler,
  selectedContract,
  setSelectedContract,
  handleContractSelect,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  insuredName,
  setInsuredName,
  policyNumber,
  setPolicyNumber,
  classOfBusiness,
  setClassOfBusiness,
  coverholderName,
  setCoverholderName,
  state,
  setState,
  handleSubmit,
  loading,
  error
}) {
  const [expanded, setExpanded] = useState(true);
  const [advancedExpanded, setAdvancedExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const toggleAdvancedExpand = () => {
    setAdvancedExpanded(!advancedExpanded);
  };

  const wholesalers = useMemo(() => Object.keys(wholesalerContractMap), [wholesalerContractMap]);

  const allContracts = useMemo(() => {
    return Object.values(wholesalerContractMap).flatMap(wholesaler => wholesaler.contracts || []);
  }, [wholesalerContractMap]);

  const filteredContracts = useMemo(() => {
    if (selectedWholesaler && wholesalerContractMap[selectedWholesaler]) {
      return wholesalerContractMap[selectedWholesaler].contracts || [];
    }
    return allContracts;
  }, [selectedWholesaler, wholesalerContractMap, allContracts]);

  const handleWholesalerChange = (event) => {
    const newWholesaler = event.target.value;
    setSelectedWholesaler(newWholesaler);
    setSelectedContract('');
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" gutterBottom>
          Filter Data
        </Typography>
        <IconButton onClick={toggleExpand} size="small">
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      <Collapse in={expanded}>
        <form onSubmit={handleSubmit}>
          <Select
            value={selectedWholesaler}
            onChange={handleWholesalerChange}
            displayEmpty
            fullWidth
            sx={{ mb: 3 }}
            required
          >
            <MenuItem value="" disabled>Select Wholesaler</MenuItem>
            {wholesalers.map((wholesaler) => (
              <MenuItem key={wholesaler} value={wholesaler}>
                {wholesaler}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={selectedContract}
            onChange={(e) => handleContractSelect(e.target.value)}
            displayEmpty
            fullWidth
            sx={{ mb: 3 }}
          >
            <MenuItem value="">All Contracts</MenuItem>
            {filteredContracts.map((contract) => (
              <MenuItem key={contract.id} value={contract.id}>
                {contract.id}
              </MenuItem>
            ))}
          </Select>
          <Button
            onClick={toggleAdvancedExpand}
            fullWidth
            variant="outlined"
            sx={{ mb: 3 }}
          >
            {advancedExpanded ? 'Hide Advanced Filters' : 'Show Advanced Filters'}
          </Button>
          <Collapse in={advancedExpanded}>
            <InputLabel htmlFor="startDate">Start Date</InputLabel>
            <Box component="input"
              type="date"
              id="startDate"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              required
              fullWidth
              sx={{ mb: 3, p: 1, border: '1px solid #ccc', borderRadius: 1 }}
            />
            <InputLabel htmlFor="endDate">End Date</InputLabel>
            <Box component="input"
              type="date"
              id="endDate"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              required
              fullWidth
              sx={{ mb: 3, p: 1, border: '1px solid #ccc', borderRadius: 1 }}
            />
            <TextField
              label="Insured Name"
              value={insuredName}
              onChange={(e) => setInsuredName(e.target.value)}
              fullWidth
              sx={{ mb: 3 }}
            />
            <TextField
              label="Policy Number"
              value={policyNumber}
              onChange={(e) => setPolicyNumber(e.target.value)}
              fullWidth
              sx={{ mb: 3 }}
            />
            <TextField
              label="Class of Business"
              value={classOfBusiness}
              onChange={(e) => setClassOfBusiness(e.target.value)}
              fullWidth
              sx={{ mb: 3 }}
            />
            <TextField
              label="Coverholder Name"
              value={coverholderName}
              onChange={(e) => setCoverholderName(e.target.value)}
              fullWidth
              sx={{ mb: 3 }}
            />
            <TextField
              label="State"
              value={state}
              onChange={(e) => setState(e.target.value)}
              fullWidth
              sx={{ mb: 3 }}
            />
          </Collapse>
          <FetchDataButton
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Fetch Data'}
          </FetchDataButton>
        </form>
        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </Collapse>
    </Box>
  );
}

export default FilterOptions;