import React, { useState, useMemo } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  TablePagination,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import * as XLSX from 'xlsx';

function ClaimsItemsDataTable({ data }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const parseDate = (dateString) => {
    if (dateString.includes('_')) {
      const [year, month] = dateString.split('_');
      return new Date(parseInt(year), parseInt(month) - 1);
    } else {
      const [year, month] = dateString.split('-');
      return new Date(parseInt(year), parseInt(month) - 1);
    }
  };

  const formatColumnHeader = (column) => {
    if (column.match(/^\d{4}[-_]\d{2}$/)) {
      const [year, month] = column.split(/[-_]/);
      const date = new Date(parseInt(year), parseInt(month) - 1);
      return date.toLocaleString('default', { month: 'short', year: '2-digit' });
    }
    return replaceUnderscores(column);
  };

  const formatDateCell = (dateString) => {
    if (dateString && dateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
      const [year, month, day] = dateString.split('-');
      const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
      return date.toLocaleString('default', { day: '2-digit', month: 'short', year: '2-digit' });
    }
    return dateString;
  };

  const formatNumber = (value) => {
    if (typeof value === 'number') {
      return value.toLocaleString('en-US', { maximumFractionDigits: 2 });
    }
    return value;
  };

  const replaceUnderscores = (text) => {
    return typeof text === 'string' ? text.replace(/_/g, ' ') : text;
  };

  const getLastThreeDigits = (str) => {
    const digits = str.replace(/\D/g, '');
    return digits.slice(-3).padStart(3, '0');
  };

  const processedData = useMemo(() => {
    if (!Array.isArray(data) || data.length === 0) return [];

    const processed = data.map(row => {
      const newRow = { ...row };
      Object.keys(newRow).forEach(key => {
        if (newRow[key] === '' || newRow[key] === 'nan' || newRow[key] === null || newRow[key] === undefined) {
          newRow[key] = '-';
        } else if (typeof newRow[key] === 'number' && isNaN(newRow[key])) {
          newRow[key] = '-';
        }
      });
      return newRow;
    });

    return processed.sort((a, b) => {
      const aDigits = getLastThreeDigits(a.Claim_Number);
      const bDigits = getLastThreeDigits(b.Claim_Number);
      return aDigits.localeCompare(bDigits);
    });
  }, [data]);

  const handleExportExcel = () => {
    const flattenedData = processedData.map(row => {
      const flatRow = { ...row };
      Object.keys(flatRow).forEach(key => {
        if (typeof flatRow[key] === 'object') {
          Object.keys(flatRow[key]).forEach(subKey => {
            flatRow[`${key}_${subKey}`] = flatRow[key][subKey];
          });
          delete flatRow[key];
        }
      });
      return flatRow;
    });
    const ws = XLSX.utils.json_to_sheet(flattenedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    XLSX.writeFile(wb, "claims_data.xlsx");
  };

  const renderCellContent = (row, column) => {
    if (column === 'Inception_Date' || column === 'Expiry_Date') {
      return formatDateCell(row[column]);
    } else if (typeof row[column] === 'object') {
      return null;
    } else {
      return column.includes('20') ? formatNumber(row[column]) : row[column];
    }
  };

  if (!Array.isArray(data)) {
    return (
      <Typography variant="body1" align="center" sx={{ p: 2, color: 'text.secondary' }}>
        Invalid data format. Please try again.
      </Typography>
    );
  }

  if (data === null || data.length === 0) {
    return (
      <Typography variant="body1" align="center" sx={{ p: 2, color: 'text.secondary' }}>
        No data for your filter parameters.
      </Typography>
    );
  }

  if (!processedData || processedData.length === 0) {
    return (
      <Typography variant="body1" align="center" sx={{ p: 2, color: 'text.secondary' }}>
        No data selected.
      </Typography>
    );
  }

  const columns = Object.keys(processedData[0]);
  const monthColumns = columns.filter(col => col.match(/^\d{4}[-_]\d{2}$/));
  const regularColumns = columns.filter(col => !col.match(/^\d{4}[-_]\d{2}$/));
  const subColumns = ['Indemnity', 'Subro_and_Salvage', 'Fees'];
  const totalIncurredColumns = ['Combined', ...subColumns];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Paper sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <TableContainer sx={{ flexGrow: 1, height: 0, overflow: 'auto' }}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                {regularColumns.map((column, index) => (
                  <TableCell
                    key={column}
                    style={{
                      position: 'sticky',
                      top: 0,
                      left: index === 0 ? 0 : index === 1 ? '200px' : 'auto',
                      zIndex: index < 2 ? 4 : 3,
                      backgroundColor: '#f5f5f5',
                      minWidth: index < 2 ? '200px' : '150px',
                      maxWidth: index < 2 ? '200px' : '150px',
                      textAlign: 'left',
                      fontWeight: 'bold',
                      padding: '8px 16px',
                    }}
                    rowSpan={2}
                  >
                    {formatColumnHeader(column)}
                  </TableCell>
                ))}
                {monthColumns.flatMap((month) => [
                  <TableCell
                    key={`${month}_Settled`}
                    colSpan={subColumns.length}
                    style={{
                      position: 'sticky',
                      top: 0,
                      zIndex: 2,
                      textAlign: 'center',
                      backgroundColor: '#e0e0e0',
                      fontWeight: 'bold',
                      padding: '8px 16px',
                      borderBottom: '1px solid #bdbdbd',
                    }}
                  >
                    {`${formatColumnHeader(month)} Settled`}
                  </TableCell>,
                  <TableCell
                    key={`${month}_Total`}
                    colSpan={totalIncurredColumns.length}
                    style={{
                      position: 'sticky',
                      top: 0,
                      zIndex: 2,
                      textAlign: 'center',
                      backgroundColor: '#d0d0d0',
                      fontWeight: 'bold',
                      padding: '8px 16px',
                      borderBottom: '1px solid #bdbdbd',
                    }}
                  >
                    {`${formatColumnHeader(month)} Total`}
                  </TableCell>
                ])}
              </TableRow>
              <TableRow>
                {monthColumns.flatMap((month) => [
                  ...subColumns.map((subColumn) => (
                    <TableCell
                      key={`${month}_Settled_${subColumn}`}
                      style={{
                        position: 'sticky',
                        top: '41px',
                        zIndex: 2,
                        textAlign: 'right',
                        backgroundColor: '#f0f0f0',
                        fontWeight: 'bold',
                        padding: '4px 8px',
                        fontSize: '0.8rem',
                      }}
                    >
                      {replaceUnderscores(subColumn)}
                    </TableCell>
                  )),
                  ...totalIncurredColumns.map((subColumn) => (
                    <TableCell
                      key={`${month}_Total_${subColumn}`}
                      style={{
                        position: 'sticky',
                        top: '41px',
                        zIndex: 2,
                        textAlign: 'right',
                        backgroundColor: '#e8e8e8',
                        fontWeight: 'bold',
                        padding: '4px 8px',
                        fontSize: '0.8rem',
                      }}
                    >
                      {replaceUnderscores(subColumn)}
                    </TableCell>
                  ))
                ])}
              </TableRow>
            </TableHead>
            <TableBody>
              {processedData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowIndex) => (
                  <TableRow hover key={rowIndex} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                    {regularColumns.map((column, columnIndex) => (
                      <TableCell 
                        key={column}
                        style={{ 
                          position: columnIndex < 2 ? 'sticky' : 'static',
                          left: columnIndex === 0 ? 0 : columnIndex === 1 ? '200px' : 'auto',
                          zIndex: columnIndex < 2 ? 1 : 0,
                          minWidth: columnIndex < 2 ? '200px' : '150px',
                          maxWidth: columnIndex < 2 ? '200px' : '300px',
                          whiteSpace: 'normal',
                          backgroundColor: '#ffffff',
                          wordWrap: 'break-word',
                          textAlign: 'left',
                          padding: '8px 16px',
                        }}
                      >
                        {renderCellContent(row, column)}
                      </TableCell>
                    ))}
                    {monthColumns.flatMap((month) => [
                      ...subColumns.map((subColumn) => (
                        <TableCell
                          key={`${month}_Settled_${subColumn}`}
                          style={{
                            textAlign: 'right',
                            backgroundColor: '#ffffff',
                            padding: '6px 12px',
                          }}
                        >
                          {row[month] && row[month]['Settled'] && row[month]['Settled'][subColumn] ? formatNumber(row[month]['Settled'][subColumn]) : '-'}
                        </TableCell>
                      )),
                      ...totalIncurredColumns.map((subColumn) => (
                        <TableCell
                          key={`${month}_Total_${subColumn}`}
                          style={{
                            textAlign: 'right',
                            backgroundColor: '#fafafa',
                            padding: '6px 12px',
                          }}
                        >
                          {row[month] && row[month]['Total'] && row[month]['Total'][subColumn] ? formatNumber(row[month]['Total'][subColumn]) : '-'}
                        </TableCell>
                      ))
                    ])}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' , marginLeft: '10px'}}>
            <Tooltip title="Download as Excel">
              <IconButton onClick={handleExportExcel}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={processedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    </div>
  );
}

export default ClaimsItemsDataTable;