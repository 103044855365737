import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Select, MenuItem, Box, Typography, IconButton, Grid, FormHelperText } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FormulaDisplay from './FormulaDisplay';
import CustomTooltip from './CustomTooltip';

const columnOptions = {
  premiums: ['Policy Number', 'Insured Name', 'Class of Business', 'Period Starting', 'Policyholder Type', 'Inception Date', 'Expiry Date', 'State', 'Estimated Annual Premium', 'Payment Plan', 'Deductible', 'Deductible Basis', 'Transaction Type', 'Transaction Date', 'Gross Premium', 'Coverholder Commission (%)', 'Coverholder Commission (Amount)', 'Net Premium', 'Surplus Line Broker', 'Address of Broker', 'State of Filing', 'Broker License Number'],
  claims: ['Claim Number', 'Policy Number', 'Insured Name', 'Class of Business', 'Period Starting', 'Inception Date', 'Expiry Date', 'Surplus Line State', 'Loss Location', 'Date of Loss', 'Type of Loss', 'Loss Description', 'Denial', 'Settled - Indemnity', 'Settled - Subro and Salvage', 'Settled - Fees', 'Total - Indemnity', 'Total - Subro and Salvage', 'Total - Fees', 'Total - Combined']
};

const mandatoryColumns = {
  premiums: ['Policy Number', 'Insured Name', 'Period Starting', 'Policyholder Type', 'Inception Date', 'Expiry Date', 'Payment Plan', 'Gross Premium', 'Net Premium', 'Surplus Line Broker', 'Broker License Number'],
  claims: ['Claim Number', 'Policy Number', 'Insured Name', 'Class of Business', 'Period Starting', 'Inception Date', 'Expiry Date', 'Total - Combined']
};

const operators = ['+', '-', '*', '/', '(', ')'];

const MappingDefinition = ({ schema = [], initialMappings = {}, onMappingChange, companyName, type }) => {
  const [mappings, setMappings] = useState({});
  const [errors, setErrors] = useState({});
  const mappingsRef = useRef(mappings);

  const leafNodes = useMemo(() => {
    const getLeafNodes = (nodes, prefix = '') => {
      return nodes.flatMap(node => {
        if (!node.children || node.children.length === 0) {
          return [{ name: prefix + node.name, fullPath: prefix + node.name }];
        }
        return getLeafNodes(node.children, prefix + node.name + ' > ');
      });
    };
    return getLeafNodes(schema);
  }, [schema]);

  useEffect(() => {
    const initializeMappings = () => {
      const targetColumns = columnOptions[type] || [];
      const newMappings = {};
      const newErrors = {};
      targetColumns.forEach(column => {
        if (initialMappings[column]) {
          newMappings[column] = initialMappings[column];
        } else {
          const isMandatory = mandatoryColumns[type].includes(column);
          newMappings[column] = { 
            type: 'DIRECT', 
            value: isMandatory ? '' : 'None', 
            formulaElements: [] 
          };
          if (isMandatory) {
            newErrors[column] = 'This field is required';
          }
        }
      });
      setMappings(newMappings);
      setErrors(newErrors);
      mappingsRef.current = newMappings;
    };

    initializeMappings();
  }, [type, initialMappings]);

  const processFormulaString = useCallback((elements) => {
    return elements.map(element => 
      element.type === 'operator' ? `\`${element.value}\`` : element.value
    ).join(' ');
  }, []);

  const handleMappingChange = useCallback((targetColumn, changes) => {
    setMappings(prev => {
      const newMappings = {
        ...prev,
        [targetColumn]: { ...prev[targetColumn], ...changes }
      };
      if (newMappings[targetColumn].type === 'FORMULA') {
        newMappings[targetColumn].value = processFormulaString(newMappings[targetColumn].formulaElements);
      }
      mappingsRef.current = newMappings;
      return newMappings;
    });

    setErrors(prev => {
      const newErrors = { ...prev };
      if (mandatoryColumns[type].includes(targetColumn) && changes.value !== '') {
        delete newErrors[targetColumn];
      }
      return newErrors;
    });

    setTimeout(() => {
      onMappingChange(mappingsRef.current);
    }, 0);
  }, [type, processFormulaString, onMappingChange]);

  const handleAddFormulaElement = useCallback((targetColumn, element) => {
    const newElement = operators.includes(element) 
      ? { type: 'operator', value: element }
      : { type: 'variable', value: element };
    
    handleMappingChange(targetColumn, {
      formulaElements: [...(mappingsRef.current[targetColumn].formulaElements || []), newElement],
    });
  }, [handleMappingChange]);

  const handleClearFormula = useCallback((targetColumn) => {
    const isMandatory = mandatoryColumns[type].includes(targetColumn);
    handleMappingChange(targetColumn, { 
      value: isMandatory ? '' : 'None', 
      formulaElements: [] 
    });
    if (isMandatory) {
      setErrors(prev => ({ ...prev, [targetColumn]: 'This field is required' }));
    }
  }, [type, handleMappingChange]);

  return (
    <Box>
      <Typography variant="h6" gutterBottom>{type.charAt(0).toUpperCase() + type.slice(1)} Column Mapping
        <CustomTooltip title="Define the Mapping" content="Here you need to define which base level columns in the format you defined correspond to the columns in our database." />
      </Typography>
      {columnOptions[type]?.map(targetColumn => (
        <Grid container spacing={2} alignItems="center" key={targetColumn} sx={{ mb: 2 }}>
          <Grid item xs={2}>
            <Typography>{targetColumn}{mandatoryColumns[type].includes(targetColumn) && ' *'}:</Typography>
          </Grid>
          <Grid item xs={2}>
            <Select
              fullWidth
              value={mappings[targetColumn]?.type || 'DIRECT'}
              onChange={(e) => handleMappingChange(targetColumn, { type: e.target.value, value: '', formulaElements: [] })}
            >
              <MenuItem value="DIRECT">Direct Mapping
                <CustomTooltip title="Direct Mapping" content="Columns the database will be assigned directly to a base level column in the incoming bordereau." />
              </MenuItem>
              <MenuItem value="FORMULA">Use Formula
                <CustomTooltip title="Use Formula" content="Columns in the database will be calculated as a combination of the base level columns in the incoming bordereau according to the formula you define." />
              </MenuItem>
            </Select>
          </Grid>
          {mappings[targetColumn]?.type === 'DIRECT' ? (
            <Grid item xs={8}>
              <Select
                fullWidth
                value={mappings[targetColumn]?.value || ''}
                onChange={(e) => handleMappingChange(targetColumn, { value: e.target.value })}
                error={!!errors[targetColumn]}
              >
                {!mandatoryColumns[type].includes(targetColumn) && (
                  <MenuItem value="None">
                    <em>None</em>
                  </MenuItem>
                )}
                {leafNodes.map(node => (
                  <MenuItem key={node.fullPath} value={node.fullPath}>
                    {node.name}
                  </MenuItem>
                ))}
              </Select>
              {errors[targetColumn] && <FormHelperText error>{errors[targetColumn]}</FormHelperText>}
            </Grid>
          ) : (
            <>
              <Grid item xs={4}>
                <FormulaDisplay formulaElements={mappings[targetColumn]?.formulaElements || []} />
              </Grid>
              <Grid item xs={3}>
                <Select
                  fullWidth
                  value=""
                  onChange={(e) => {
                    if (e.target.value) {
                      handleAddFormulaElement(targetColumn, e.target.value);
                    }
                  }}
                  displayEmpty
                >
                  <MenuItem value="" disabled>Add Element</MenuItem>
                  {operators.map(op => (
                    <MenuItem key={op} value={op}>{op}</MenuItem>
                  ))}
                  {leafNodes.map(node => (
                    <MenuItem key={node.fullPath} value={node.fullPath}>
                      {node.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => handleClearFormula(targetColumn)} size="small">
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </>
          )}
        </Grid>
      ))}
    </Box>
  );
};

export default MappingDefinition;