import React, { createContext, useState, useEffect, useContext } from 'react';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { useAuth } from './AuthContext';
import { db } from '../services/firebase';

const DashboardContext = createContext();

export function useDashboard() {
  return useContext(DashboardContext);
}

export function DashboardProvider({ children }) {
  const [wholesalerContractMap, setWholesalerContractMap] = useState({});
  const [selectedWholesaler, setSelectedWholesaler] = useState('');
  const [selectedContract, setSelectedContract] = useState('');
  const [startDate, setStartDate] = useState('2000-01-01');
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [insuredName, setInsuredName] = useState('');
  const [policyNumber, setPolicyNumber] = useState('');
  const [classOfBusiness, setClassOfBusiness] = useState('');
  const [coverholderName, setCoverholderName] = useState('');
  const [state, setState] = useState('');
  const [premiumsTableData, setPremiumsTableData] = useState([]);
  const [claimsSummaryTableData, setClaimsSummaryTableData] = useState([]);
  const [claimsItemsTableData, setClaimsItemsTableData] = useState([]);
  const [ratioTableData, setRatioTableData] = useState([]);
  const [latePayments, setLatePayments] = useState({});
  const [paymentsSummary, setPaymentsSummary] = useState({});
  const [sanctionsData, setSanctionsData] = useState([]);
  const [selectedPremiumColumns, setSelectedPremiumColumns] = useState([]);
  const [selectedClaimsColumns, setSelectedClaimsColumns] = useState([]);
  const { companyName, currentUserPlatform } = useAuth();
  const [showTooltips, setShowTooltips] = useState(true);


  useEffect(() => {
    const fetchWholesalersAndContracts = async () => {
      if (companyName && currentUserPlatform) {
        try {
          const wholesalersCollection = collection(db, `users/${companyName}/wholesalers`);
          const wholesalersSnapshot = await getDocs(wholesalersCollection);
          const tempWholesalerContractMap = {};
          for (const wholesalerDoc of wholesalersSnapshot.docs) {
            const wholesalerId = wholesalerDoc.id;
            const wholesalerData = wholesalerDoc.data();
            tempWholesalerContractMap[wholesalerId] = {
              ...wholesalerData,
              id: wholesalerId,
              contracts: []
            };
            const contractsCollection = collection(
              db,
              `users/${companyName}/wholesalers/${wholesalerId}/contracts`
            );
            const contractsSnapshot = await getDocs(contractsCollection);

            contractsSnapshot.docs.forEach(contractDoc => {
              tempWholesalerContractMap[wholesalerId].contracts.push({
                id: contractDoc.id,
                ...contractDoc.data()
              });
            });
          }

          setWholesalerContractMap(tempWholesalerContractMap);
        } catch (error) {
          console.error('Error fetching wholesalers and contracts:', error);
        }
      }
    };

    fetchWholesalersAndContracts();
  }, [companyName, currentUserPlatform]);

  useEffect(() => {
    const fetchColumnSettings = async () => {
      if (companyName && currentUserPlatform) {
        try {
          const companyDoc = await getDoc(doc(db, 'users', companyName));

          if (companyDoc.exists()) {
            const companyData = companyDoc.data();
            setSelectedPremiumColumns(companyData.premiums_target_columns || []);
            setSelectedClaimsColumns(companyData.claims_target_columns || []);
          }
        } catch (error) {
          console.error('Error fetching column settings:', error);
        }
      }
    };

    fetchColumnSettings();
  }, [companyName, currentUserPlatform]);

  const value = {
    wholesalerContractMap,
    setWholesalerContractMap,
    selectedWholesaler,
    setSelectedWholesaler,
    selectedContract,
    setSelectedContract,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    insuredName,
    setInsuredName,
    policyNumber,
    setPolicyNumber,
    classOfBusiness,
    setClassOfBusiness,
    coverholderName,
    setCoverholderName,
    state,
    setState,
    premiumsTableData,
    setPremiumsTableData,
    claimsSummaryTableData,
    setClaimsSummaryTableData,
    claimsItemsTableData,
    setClaimsItemsTableData,
    ratioTableData,
    setRatioTableData,
    latePayments,
    setLatePayments,
    paymentsSummary,
    setPaymentsSummary,
    sanctionsData,
    setSanctionsData,
    selectedPremiumColumns,
    setSelectedPremiumColumns,
    selectedClaimsColumns,
    setSelectedClaimsColumns,
    showTooltips,
    setShowTooltips,
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
}