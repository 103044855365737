import React from 'react';
import { Tooltip, IconButton, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useDashboard } from '../contexts/DashboardContext';

const CustomTooltip = ({ title, content }) => {
  const { showTooltips } = useDashboard();

  if (!showTooltips) return null;

  return (
    <Tooltip
      title={
        <React.Fragment>
          <Typography color="inherit">{title}</Typography>
          {content}
        </React.Fragment>
      }
    >
      <IconButton size="small">
        <HelpOutlineIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default CustomTooltip;