import React, { useState, useEffect, useRef } from 'react';
import { db, storage } from '../services/firebase';
import { collection, getDocs, addDoc, query, where, onSnapshot } from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';
import { TextField, Typography, Box, Select, MenuItem, Grid, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { FetchSubmitButton } from './Buttons';
import { useDashboard } from '../contexts/DashboardContext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';

function AddBordereaux({ companyName, borderauType }) {
  const { wholesalerContractMap } = useDashboard();
  const [files, setFiles] = useState([]);
  const [selectedWholesaler, setSelectedWholesaler] = useState('');
  const [selectedContract, setSelectedContract] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [cleanedSheets, setCleanedSheets] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [conflictingFile, setConflictingFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length > 30) {
      setError('You can only upload up to 30 files at once.');
      return;
    }
    setFiles(selectedFiles);
  };

  const handleWholesalerChange = (e) => {
    setSelectedWholesaler(e.target.value);
    setSelectedContract('');
  };

  const handleContractChange = (e) => {
    setSelectedContract(e.target.value);
  };

  const checkExistingFile = async (fileName) => {
    const q = query(
      collection(db, `users/${companyName}/wholesalers/${selectedWholesaler}/contracts/${selectedContract}/raw_${borderauType}_bordereaux`),
      where('fileName', '==', fileName)
    );
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsLoading(true);
    setIsButtonDisabled(true);
  
    if (files.length === 0 || !selectedWholesaler || !selectedContract) {
      setError('Please select at least one file, a wholesaler, and a contract');
      setIsLoading(false);
      setIsButtonDisabled(false);
      return;
    }
  
    try {
      for (const file of files) {
        const sanitizedFileName = file.name
          .replace(/[^a-zA-Z0-9.-]/g, '_')
          .replace(/_{2,}/g, '_')       
          .replace(/^_|_$/g, '');
        
        const fileExists = await checkExistingFile(sanitizedFileName);
        if (fileExists) {
          setConflictingFile({ file, sanitizedFileName });
          setConfirmDialogOpen(true);
          setIsLoading(false);
          setIsButtonDisabled(false);
          return;
        }
      }
      
      await uploadFiles();
    } catch (err) {
      handleUploadError(err);
    } finally {
      setIsLoading(false);
      setTimeout(() => setIsButtonDisabled(false), 1000);
    }
  };

  const uploadFiles = async () => {
    const uploadedFileNames = [];
    for (const file of files) {
      const sanitizedFileName = file.name
        .replace(/[^a-zA-Z0-9.-]/g, '_')
        .replace(/_{2,}/g, '_')       
        .replace(/^_|_$/g, '');
      const storageRef = ref(storage, `users/${companyName}/wholesalers/${selectedWholesaler}/contracts/${selectedContract}/bordereaux/${sanitizedFileName}`);
      
      await uploadBytes(storageRef, file);
  
      await addDoc(collection(db, `users/${companyName}/wholesalers/${selectedWholesaler}/contracts/${selectedContract}/raw_${borderauType}_bordereaux`), {
        fileName: sanitizedFileName,
        uploadDate: new Date(),
        cleaned_sheets: [],
        date: '',
        path: `users/${companyName}/wholesalers/${selectedWholesaler}/contracts/${selectedContract}/raw_${borderauType}_bordereaux`,
        prefix: `users/${companyName}/wholesalers/${selectedWholesaler}/contracts/${selectedContract}/bordereaux/${sanitizedFileName}`,
      });

      uploadedFileNames.push(sanitizedFileName);
    }

    setSuccess(`${borderauType.charAt(0).toUpperCase() + borderauType.slice(1)} bordereaux uploaded successfully! Please wait a couple of minutes for the update to propagate.`);
    setUploadedFiles(uploadedFileNames);
    clearUploadSection();
  };

  const handleUploadError = (err) => {
    let errorMessage = 'Failed to upload bordereaux';
    if (err.code === 'storage/unauthorized') {
      errorMessage += ': Unauthorized access. Please check your permissions.';
    } else if (err.code === 'storage/canceled') {
      errorMessage += ': Upload was canceled.';
    } else if (err.code === 'storage/unknown') {
      errorMessage += ': An unknown error occurred. Please check the console for more details.';
    } else {
      errorMessage += `: ${err.message}`;
    }
    setError(errorMessage);
  };

  const clearUploadSection = () => {
    setFiles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleConfirmUpload = async () => {
    setConfirmDialogOpen(false);
    setIsLoading(true);
    try {
      await uploadFiles();
    } catch (err) {
      handleUploadError(err);
    } finally {
      setIsLoading(false);
      setConflictingFile(null);
      setTimeout(() => setIsButtonDisabled(false), 1000);
    }
  };

  const handleCancelUpload = () => {
    setConfirmDialogOpen(false);
    setConflictingFile(null);
    clearUploadSection();
  };

  const watchCleanedBordereaux = () => {
    if (uploadedFiles.length === 0 || !selectedWholesaler || !selectedContract) return;

    const q = query(
      collection(db, `users/${companyName}/wholesalers/${selectedWholesaler}/contracts/${selectedContract}/cleaned_${borderauType}_bordereaux`),
      where('original_file_name', 'in', uploadedFiles)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const updatedSheets = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCleanedSheets(updatedSheets);
    });

    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = watchCleanedBordereaux();
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [uploadedFiles, selectedWholesaler, selectedContract, borderauType]);

  const CleanedSheetsStatus = () => (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h6" gutterBottom>
        Ingestion Status
      </Typography>
      <Grid container spacing={2}>
        {cleanedSheets.map((sheet) => (
          <Grid item xs={12} sm={6} md={4} key={sheet.id}>
            <Paper sx={{ p: 2, display: 'flex', alignItems: 'flex-start', height: '100%' }}>
              {sheet.status === 'Completed' && <CheckCircleIcon color="success" sx={{ mr: 1, mt: 0.5 }} />}
              {sheet.status === 'Pending' && <CircularProgress size={20} sx={{ mr: 1, mt: 0.5 }} />}
              {sheet.status === 'Processing' && <CircularProgress size={20} sx={{ mr: 1, mt: 0.5 }} />}
              {sheet.status === 'Failed' && <CancelIcon color="error" sx={{ mr: 1, mt: 0.5 }} />}
              <Box sx={{ flexGrow: 1, minWidth: 0 }}>
                <Typography variant="subtitle1" noWrap={false} sx={{ wordBreak: 'break-word' }}>
                  {sheet.original_file_name}: {sheet.sheet_name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Status: {sheet.status}
                </Typography>
                {sheet.error && <Typography variant="body2" color="text.secondary" noWrap={false} sx={{ wordBreak: 'break-word' }}>
                  {sheet.error}
                </Typography>}
                {sheet.message && <Typography variant="body2" color="text.secondary" noWrap={false} sx={{ wordBreak: 'break-word' }}>
                  {sheet.message}
                </Typography>}
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  return (
    <Box>
      <Typography component="h2" variant="h6" gutterBottom>
        Upload {borderauType.charAt(0).toUpperCase() + borderauType.slice(1)} Bordereaux
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      {success && <Typography color="primary">{success}</Typography>}
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <Select
          value={selectedWholesaler}
          onChange={handleWholesalerChange}
          displayEmpty
          fullWidth
          sx={{ mb: 2 }}
          disabled={isLoading}
        >
          <MenuItem value="" disabled>Select Wholesaler</MenuItem>
          {Object.keys(wholesalerContractMap).map((wholesalerId) => (
            <MenuItem key={wholesalerId} value={wholesalerId}>
              {wholesalerContractMap[wholesalerId].name || wholesalerId}
            </MenuItem>
          ))}
        </Select>
        <Select
          value={selectedContract}
          onChange={handleContractChange}
          displayEmpty
          fullWidth
          sx={{ mb: 2 }}
          disabled={isLoading || !selectedWholesaler}
        >
          <MenuItem value="" disabled>Select Contract</MenuItem>
          {selectedWholesaler &&
            wholesalerContractMap[selectedWholesaler].contracts.map((contract) => (
              <MenuItem key={contract.id} value={contract.id}>
                {contract.id}
              </MenuItem>
            ))}
        </Select>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="file"
          id="bordereaux"
          name="bordereaux"
          onChange={handleFileChange}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            multiple: true,
            accept: '.xlsx,.xls,.csv'
          }}
          disabled={isLoading}
          inputRef={fileInputRef}
        />
        {cleanedSheets.length > 0 && <CleanedSheetsStatus />}
        <FetchSubmitButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={isLoading || isButtonDisabled}
        >
          {isLoading ? 'Uploading Bordereaux...' : `Upload ${files.length} Bordereaux`}
        </FetchSubmitButton>
      </Box>
      <Dialog
        open={confirmDialogOpen}
        onClose={handleCancelUpload}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm File Upload"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to upload this file? A file with the name "{conflictingFile?.sanitizedFileName}" already exists.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FetchSubmitButton onClick={handleCancelUpload} color="primary">
            No
          </FetchSubmitButton>
          <FetchSubmitButton onClick={handleConfirmUpload} color="primary" autoFocus>
            Yes
          </FetchSubmitButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AddBordereaux;