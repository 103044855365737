import React from 'react';
import { Grid, Paper } from '@mui/material';
import FilterOptions from './FilterOptions';
import AISummary from './AISummary';
import Sanctions from './Sanctions';
import { useDashboard } from '../contexts/DashboardContext';

function Sidebar({
  wholesalerContractMap,
  selectedWholesaler,
  setSelectedWholesaler,
  selectedContract,
  setSelectedContract,
  handleContractSelect,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  insuredName,
  setInsuredName,
  policyNumber,
  setPolicyNumber,
  classOfBusiness,
  setClassOfBusiness,
  coverholderName,
  setCoverholderName,
  state,
  setState,
  handleSubmit,
  loading,
  error,
  onAIQuery,
  aiLoading,
  messageHistory,
}) {
  const { paymentsSummary, sanctionsData } = useDashboard();

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <FilterOptions
            wholesalerContractMap={wholesalerContractMap}
            selectedWholesaler={selectedWholesaler}
            setSelectedWholesaler={setSelectedWholesaler}
            selectedContract={selectedContract}
            setSelectedContract={setSelectedContract}
            handleContractSelect={handleContractSelect}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            insuredName={insuredName}
            setInsuredName={setInsuredName}
            policyNumber={policyNumber}
            setPolicyNumber={setPolicyNumber}
            classOfBusiness={classOfBusiness}
            setClassOfBusiness={setClassOfBusiness}
            coverholderName={coverholderName}
            setCoverholderName={setCoverholderName}
            state={state}
            setState={setState}
            handleSubmit={handleSubmit}
            loading={loading}
            error={error}
          />
        </Paper>
      </Grid>
      <Grid item>
        <Paper sx={{ py: 3, px: 2, display: 'flex', flexDirection: 'column' }}>
          <AISummary paymentsSummary={paymentsSummary} />
        </Paper>
      </Grid>
      <Grid item>
        <Paper sx={{ py: 3, px: 2, display: 'flex', flexDirection: 'column' }}>
          <Sanctions sanctionsData={sanctionsData} />
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Sidebar;