import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import PremiumsDataTable from './PremiumsDataTable';
import ClaimsSummaryDataTable from './ClaimsSummaryDataTable';
import ClaimsItemsDataTable from './ClaimsItemsDataTable';

function DataTabs({ premiumsData, claimsSummaryData, claimsItemsData, ratioData, latePayments }) {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box sx={{ width: '100%', height: '90%'}}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTab} onChange={handleTabChange} aria-label="data tabs">
          <Tab label="Premiums" />
          <Tab label="Claims Summary" />
          <Tab label="Claims Items" />
          {/* <Tab label="Ratio" /> */}
        </Tabs>
      </Box>
      <Box sx={{ pt: 2, height: '100%' }}>
        {currentTab === 0 && (
          <PremiumsDataTable data={premiumsData} latePayments={latePayments} />
        )}
        {currentTab === 1 && (
          <ClaimsSummaryDataTable data={claimsSummaryData} />
        )}
        {currentTab === 2 && (
          <ClaimsItemsDataTable data={claimsItemsData} />
        )}
        {/* {currentTab === 3 && (
          <ClaimsDataTable data={ratioData} />
        )} */}
      </Box>
    </Box>
  );
}

export default DataTabs;